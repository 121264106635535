import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Layout, Content } from 'layouts';
import '../styles/prism';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import { create } from 'apisauce';
import Constants from '../../static/Constants.jsx';
import styles from './country.styles';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import VerticalFixedNav from '../styles/verticalFixedNav';
import getHeaderLInks from '../shared/headerLinks';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { Slide } from 'react-reveal';
import Twitter from '@material-ui/icons/Twitter';
import { FaIndustry, FaBookReader } from 'react-icons/fa';
import { MdGroup } from 'react-icons/md';
import { AiFillProject } from 'react-icons/ai';
//import Instagram from '@material-ui/icons/Instagram';
import NavPills from '../components/NavPills/NavPills';
//import InstaGrid from '../components/InstaGrid';
import { Link } from 'gatsby';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { find, isArray, isEmpty } from 'lodash';

const apiSauceInstance = create({ baseURL: Constants.apiUrl });
const baseURL = Constants.apiUrl;
const imageFolderPath = '/resources/CountryImages/';

const {
  CountryImage,
  Description,
  ImageStyle,
  HeadingSection,
  Instagram_Twitter,
  ImageStyleMobile,
  //InstagramFeeds,
  // MatLogo
} = styles;

const { VerticalFixedNavStyles, smoothScrolling } = VerticalFixedNav;

const Country = ({ ...props }) => {
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const programLink = pageContext.programUrl;

  const countryLink = data.url;
  const headerLinks = getHeaderLInks(
    '',
    programLink,
    data.noLinks,
    countryLink
  );

  //const { screenName } = (data || {}).node || {};
  //const instaAccessToken = Constants.instagram_access_token;

  const logos = pageContext.logos || [];

  const { rawMarkdownBody, frontmatter } =
    (data.content || {}).childMarkdownRemark || {};
  const { title, countryID, name, description } = frontmatter || data;

  const fluid = (
    ((frontmatter || data || {}).cover || {}).childImageSharp || {}
  ).fluid;
  const background = (
    ((frontmatter || data).background || {}).childImageSharp || {}
  ).fluid;
  const { headerLogo, headerLogoScroll } = data;

  const [screenName, setScrenName] = useState('');
  // const [campaignCount, setCampaignCount] = useState();

  const { t, ready } = useTranslation();

  useEffect(() => {
    const isIE = /*@cc_on!@*/ false || !!(document || {}).documentMode;
    if (!isIE) {
      smoothScrolling();
    }
  }, []);

  const getCoverImage = () => {
    let countryName = '';
    if (title != undefined) {
      countryName = title.split(' ').join('_');
      let objCover = `${baseURL}/${imageFolderPath}${countryID}/${countryName}.jpg`;
      if (objCover) {
        return objCover;
      }
    } else if (name != undefined) {
      countryName = name.split(' ').join('_');
      let objCover = `${baseURL}/${imageFolderPath}${countryID}/${countryName}.jpg`;
      if (objCover) {
        return objCover;
      }
    } else {
      return '';
    }
  };

  // useEffect(() => {
  //   const isIE = /*@cc_on!@*/ false || !!(document || {}).documentMode;
  //   if (!isIE) {
  //     smoothScrolling();
  //   }
  //   apiSauceInstance
  //     .get(`${Constants.getCampaignDetails}/${countryID}`)
  //     .then(({ data, status }) => {
  //       if (status !== 400) {
  //         if (data) {
  //           // setCampaignDetails(data);
  //           // setCampaignCount(data.length);
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       if (error) {
  //         //do something
  //       }
  //     });
  // }, []);

  useEffect(() => {
    apiSauceInstance
      .get(
        `${Constants.getNationalLevelTwitterByCountryId}/?countryID=${countryID}`
      )
      .then(({ data, status }) => {
        if (status !== 400) {
          if (data) {
            setScrenName(data);
          } else {
            setScrenName('InvestinACP');
          }
        }
      })
      .catch(error => {
        if (error) {
          //do something
        }
        setScrenName('InvestinACP');
      });
  }, []);

  let countries_data =
    t('countries_data', {
      returnObjects: true,
    }) || [];

  const getCountryData = countryID => {
    if (!ready) {
      return 'Loading...';
    } else {
      let data = find(countries_data, { countryID: countryID });
      return data;
    }
  };

  return (
    <Layout
      programLink={programLink}
      imageName={getCoverImage() != '' ? getCoverImage() : 'country_tree.jpg'}
      imageFluid={background}
      customView={getCoverImage()}
      headerLinks={headerLinks}
      logos={logos}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      isCountry={true}
      visitedLinks={[
        { url: '/', key: 'home', name: 'Home' },
        { url: '/countries', key: 'countries', name: 'Countries' },
      ]}
      currentPage={t(getCountryData(countryID)?.countryName)}
    >
      <div className="container">
        <Container css={VerticalFixedNavStyles}>
          <section id="info" className="cd-section">
            <CountryImage>
              {mobileScreen ? (
                <Img style={ImageStyleMobile} fluid={fluid}></Img>
              ) : (
                <Img style={ImageStyle} fluid={fluid}></Img>
              )}
            </CountryImage>
            <div className="country-stats">
              <GridContainer>
                <GridItem md={3} sm={6} xs={12}>
                  <Link to={`/ACP/projects?Country=${countryID}`}>
                    <Card className="card">
                      <CardContent className="countryProject-count">
                        <AiFillProject className="icon" />
                        <span>{t('projects')}</span>
                      </CardContent>
                      <CardActions className="project-action">
                        <IconButton>
                          {' '}
                          <ArrowRightAltIcon />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem md={3} sm={6} xs={12}>
                  <Link to={`/ACP/partners?Country=${countryID}`}>
                    <Card className="card">
                      <CardContent className="countryProject-count">
                        <MdGroup className="icon" />
                        <span>{t('ipi_short')}</span>
                      </CardContent>
                      <CardActions className="project-action">
                        <IconButton>
                          {' '}
                          <ArrowRightAltIcon />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem md={3} sm={6} xs={12}>
                  <Link to={`/ACP/industrialParks?Country=${countryID}`}>
                    <Card className="card">
                      <CardContent className="countryProject-count">
                        <FaIndustry className="icon" />
                        <span>{t('industrial_parks')}</span>
                      </CardContent>
                      <CardActions className="project-action">
                        <IconButton>
                          {' '}
                          <ArrowRightAltIcon />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem md={3} sm={6} xs={12}>
                  <Link to={`/ACP/publications?Country=${countryID}`}>
                    <Card className="card">
                      <CardContent className="countryProject-count">
                        <FaBookReader className="icon" />
                        <span>{t('publications')}</span>
                      </CardContent>
                      <CardActions className="project-action">
                        <IconButton>
                          {' '}
                          <ArrowRightAltIcon />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Link>
                </GridItem>
              </GridContainer>
            </div>

            <GridContainer xs={12} sm={12} md={12}>
              <GridContainer xs={12} sm={12} lg={8}>
                <Description>
                  <HeadingSection>
                    <h4>{t(getCountryData(countryID)?.countryName)}</h4>
                  </HeadingSection>
                  <div>
                    <Content
                      input={`<p><strong>${t('overview')}:</strong></p>`}
                    ></Content>
                    <Content
                      input={`<p>${getCountryData(
                        countryID
                      )?.countryDescription?.overview.join(
                        '<br /><br />'
                      )}</p>`}
                    ></Content>
                  </div>
                  {!isEmpty(
                    getCountryData(countryID)?.countryDescription?.fdi_ps
                  ) && (
                    <div>
                      <Content
                        input={`<p><strong>${t('fdi_ps')}:</strong></p>`}
                      ></Content>
                      <Content
                        input={`<p>${getCountryData(
                          countryID
                        )?.countryDescription?.fdi_ps.join(
                          '<br /><br />'
                        )}</p>`}
                      ></Content>
                    </div>
                  )}
                  {!isEmpty(
                    getCountryData(countryID)?.countryDescription?.iip_fs
                  ) && (
                    <div>
                      <Content
                        input={`<p><strong>${t('iip_fs')}:</strong></p>`}
                      ></Content>
                      <Content
                        input={`<p>${getCountryData(
                          countryID
                        )?.countryDescription?.iip_fs.join(
                          '<br /><br />'
                        )}</p>`}
                      ></Content>
                    </div>
                  )}
                  {!isEmpty(
                    getCountryData(countryID)?.countryDescription?.sources
                  ) && (
                    <div>
                      <Content
                        input={`<br/><p><strong>${t('sources')}:</strong></p>`}
                      ></Content>
                      {getCountryData(
                        countryID
                      )?.countryDescription?.sources.map((ele, index) => {
                        return (
                          <div key={index}>
                            <a
                              rel="noreferrer"
                              className="country-source-link"
                              target="_blank"
                              href={ele}
                            >
                              {ele}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Description>
              </GridContainer>
              <GridContainer xs={12} sm={12} lg={4} css={Instagram_Twitter}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={mobileScreen ? {} : { marginTop: '42px' }}
                >
                  <Slide bottom>
                    <NavPills
                      color="customPrimary"
                      alignCenter={true}
                      tabs={[
                        {
                          tabIcon: Twitter,
                          tabContent: (
                            <div className="twitterHeight">
                              {screenName && screenName.length > 0 ? (
                                <TwitterTimelineEmbed
                                  sourceType="profile"
                                  screenName={screenName}
                                  options={{ height: 560 }}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          ),
                        },
                        // {
                        //   tabIcon: Instagram,
                        //   tabContent: (
                        //     <div css={InstagramFeeds}>
                        //       <InstaGrid instaAccessToken={instaAccessToken} />
                        //     </div>
                        //   ),
                        // },
                      ]}
                    />
                  </Slide>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </section>
        </Container>
      </div>
    </Layout>
  );
};

export default Country;

Country.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
